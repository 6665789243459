import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Slides = makeShortcode("Slides");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Thoughts on culture`}</h2>
    <p>{`My favorite part of running Make School was designing an organizational culture that encouraged high performance, inspired a growth mindset, and allowed team members and students to feel human and heard. `}</p>
    <p>{`Organizations are inherently messy, and startups especially so. By virtue of being new, they are promoting a value set that stands outside the norms of our society. Often, they are proposing new ideas and new principles that create friction against the existing systems they operate in. This friction is visible both externally - in how the startup presents itself to the world - and internally. Even the most progressively minded team members hold culturally ingrained beliefs.`}</p>
    <p>{`The culture an organization develops is its competitive advantage against incumbents, more so than it's initial technology or strategy. The defensible characteristics of companies (moats) are `}<em parentName="p">{`outcomes`}</em>{` of building a strong cultural core.`}</p>
    <p>{`In the early days, culture develops implicitly. It's a byproduct of the founding team's worldview, which informs what they build, how they collaborate, and how they enter the market. As the organization matures, they attempt to make the culture more explicit through a culture deck or value statements. However, the culture - and whether it serves as a competitive advantage - depends entirely on whether the `}<strong parentName="p">{`decisions and actions`}</strong>{` of the team aligns with the stated culture.`}</p>
    <p>{`In developing Make School's culture, I studied my favorite organizations (incl. Apple, Netflix, Valve, Github) and read dozens of books on leadership and management. I've shared Make School's culture deck below (from when we were 40 people) along with the books that left the strongest impression on me. `}</p>
    <p>{`As you're taking learnings for your own organization, keep in mind that an organization's mission, industry, and stage heavily influence what operating style is best.`}</p>
    <h2>{`Make School culture deck`}</h2>
    <Slides type="keynote" title="Make School Culture Deck" contentSrc="02cqOD-LdcigUL95qLPNy6YbA" mdxType="Slides" />
    <h2>{`Books on leadership and culture`}</h2>
    <p><strong parentName="p">{`Good to Great`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/Good-Great-Some-Companies-Others/dp/0066620996"
      }}>{`Good to Great`}</a>{` by Jim Collins is the most data-driven book I've found on organization building. Collins and his team studied performance data of Fortune 500's to identify teams that consistently outperformed the market. They interviewed employees during the periods of growth to identify differentiating characteristics in culture and leadership style. Although the learnings are most relevant to later stage businesses, many of the core principles apply to startups as well.`}</p>
    <p><strong parentName="p">{`Speed of Trust`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/SPEED-Trust-Thing-Changes-Everything/dp/074329730X"
      }}>{`Speed of Trust`}</a>{` by Stephen Covey was an essential read as I shifted my role from driving critical functions to trusting execs to take ownership. It also helped our team build systems to improve cross-functional communication, which is often an achilles heel for scaling orgs. Covey identifies characteristics of high and low trust organizations and describes strategies on how to extend increasing amounts of trust without doing so blindly.`}</p>
    <p><strong parentName="p">{`High Output Management`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/High-Output-Management-Andrew-Grove/dp/0679762884"
      }}>{`High Output Management`}</a>{` by Andy Grove is perhaps the most recommended management book, and for good reason. Grove talks through the tactics on what worked to scale - and develop young leaders - during his tenure at Intel. I particularly found it helpful when thinking about modularizing our organization to put more agency in the hands of ICs.`}</p>
    <p><strong parentName="p">{`Reinventing Organizations`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/Reinventing-Organizations-Creating-Inspired-Consciousness/dp/296013351X"
      }}>{`Reinventing Organizations`}</a>{` by Frederic Laloux presents 11 examples of organizations which operate with radically progressive organizational models. These examples demonstrate the extreme of giving agency and ownership to people on the front lines of the business. The research on new org models is recent and without large amounts of data to support the theories, but it really expanded my thinking on organization design. It helped me ask deeper questions and helped me return to first principles when designing our culture.`}</p>
    <p><strong parentName="p">{`Crucial Conversations`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/Crucial-Conversations-Tools-Talking-Stakes/dp/1260474216"
      }}>{`Crucial Conversations`}</a>{` by Grenny, Patterson, McMillan, Switzler, Gregory presents framework on how to navigate critical (and often heated) discussions with colleagues. As Make School grew, my workload shifted from being task heavy to conversation heavy. Crucial conversation was helpful in improving my performance on the thing I spent most of my hours on - talking to my team.`}</p>
    <p>{`I hope these resources are helpful as you reflect on, design, and practice your organization's culture.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      